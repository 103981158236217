<template>
    <div>
      <ServerError v-if="ServerError" />
        <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="#1976d2" size="128" is-full-screen />
        <v-snackbar v-model="showsnackbar" color="black" right>
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showsnackbar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout v-if="ServerError" wrap justify-center>
            <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
                <ServerError />
            </v-flex>
        </v-layout>
        <v-layout v-else wrap justify-center>
            <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
                <v-card>
                    <v-card-title class="elevation-1">
                        Sub-Category
                        <v-spacer></v-spacer>
                        <!-- <v-row>
                <v-col cols="12" sm="7">
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="mdi-magnify"
                    solo
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row> -->

                        <v-row justify="end" class="px-5">
                            <v-dialog v-model="dialog" persistent max-width="600px" :key="dialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined color="green" v-bind="attrs" v-on="on">
                                        Add
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-form v-model="addSubcat" ref="addSubcat">
                                        <v-card-title>
                                            <span class="headline">Add Subcategory</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-text-field v-model="name" label="Sub-Category"
                                                            :rules="[rules.required]" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-select dense  v-model="category" class="mt-5"
                                                            label="Category" :items="categoryArray" item-text="name"
                                                            item-value="_id" :rules="[rules.required]" required>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <span>Add Description</span>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div id="app">
                                                            <vue-editor v-model="description"></vue-editor>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="dialogclose()">
                                                Close
                                            </v-btn>
                                            <v-btn :disabled="!addSubcat" color="blue darken-1" text @click="itemadd()">
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </v-card-title>
                    <!-- <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  dense
                  outlined
                  v-model="categoryView"
                  class="mt-5 mx-3"
                  label="Category"
                  :items="categoryArray"
                  item-text="name"
                  item-value="_id"
                >
                </v-select>
              </v-col>
            </v-row> -->
                    <v-data-table :headers="headers" :items="user" :items-per-page="count" hide-default-footer
                        class="elevation-1" :click:row="showData">
                        <template v-slot:[`item.name`]="{ item }">
                            <span v-if="item.category" style="color: darkblue">{{
                                item.name
                            }}</span>
                        </template>

                        <template v-slot:[`item.category`]="{ item }">
                                <span> {{ item.category.name }}</span>
                        </template>
                        <template v-slot:top>
                            <v-dialog v-model="editdialog" max-width="600px">
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">Edit Subcategory</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="itemediting.name"
                                                        label="Sub-Category"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select dense readonly v-model="itemediting.category" class="mt-5"
                                                        label="Category" :items="categoryArray" item-text="name"
                                                        item-value="_id">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                                <span>Edit Description</span>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <div id="app">
                                                                    <vue-editor
                                                                        v-model="itemediting.description"></vue-editor>
                                                                </div>
                                                            </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="editdialog = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="blue darken-1" text @click="edit()">
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                        <template v-slot:[`item._id`]="{ item }">
                            <v-icon small class="mr-2" @click="info(item._id)">
                                mdi-eye
                            </v-icon>
                            <v-icon small class="mr-2" @click="itemedit(item)">
                                mdi-pencil
                            </v-icon>

                            <v-dialog v-model="item.delete" max-width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small class="mr-2" v-on="on" v-bind="attrs" color="red">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Are you sure you want to delete this Subcategory?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="item.delete = false">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItem(item)">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                    </v-data-table>
                    <!-- <div class="pt-2">
                        <v-pagination :length="pages" :total-visible="7" v-model="currentPage"
                            color="#FF0000"></v-pagination>
                    </div> -->
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
    components: {
        VueEditor,
    },
    data() {
        return {
            chip: true,
            showsnackbar: false,
            msg: null,
            msg2: null,
            editdialog: false,
            ServerError: false,
            appLoading: false,
            dialogDelete: false,
            dialog: false,
            search: "",
            name: "",
            description:"",
            count: 20,
            currentPage: 1,
            pages: 0,
            totalData: 0,
            totalRows: "",
            itemid: "",
            category: [],
            categoryArray: [],
            categoryView: "",
            itemediting: [],
            a: [],
            HSN: [],
            addSubcat: false,
            editeditem: {
                name: "",
            },
            headers: [
                { text: "Sub-category Name", value: "name" },
                { text: "Category", value: "category" },
                { text: "Actions", value: "_id", sortable: false },
            ],
            rules: {
                required: (value) => !!value || "Required.",
                min: (v) => v.length >= 8 || "Min 8 characters",
            },
            user: [],
            data: [],
            cId: "",
            attachments: null,
            attachmentsformData: new FormData(),
        };
    },
    mounted() {
        this.getData();
        this.getCategory();
    },
    watch: {
        currentPage() {
            this.getData();
        },
        search() {
            this.getData();
        },
    },
    methods: {
        // getFiles() {
        //     var selectedFiles = this.categoryImg;
        //     if (this.categoryImg) {
        //         this.msg = null;
        //         this.attachments = selectedFiles;
        //     }
        // },
    showData() {},
        deleteItem(subcat) {
            var data = {};
            data["subCategoryId"] = subcat._id;
            axios({
                url: "/course/sub-category/remove",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.delete = false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = "Deleted Sucessfully";
                        this.showsnackbar = true;

                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        getData() {
            this.appLoading = true;
            axios({
                url: "/course/sub-catogory/list",

                method: "GET",
                headers: {
                    token: localStorage.getItem("token"),
                },
                params: {
                    count: this.count,
                    page: this.currentPage,
                    keyword: this.search,
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.user = response.data.data;
                    this.pages = response.data.pages;
                    this.totalData = response.data.count;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        getCategory() {
            this.appLoading = true;
            axios({
                url: "/course/catogory/list",
                method: "GET",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.categoryArray = response.data.data;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        dialogclose() {
            this.name = null;
            this.category = null;
            this.dialog = false;
        },

        itemadd() {
            var data = {};
            data["name"] = this.name;
            data["categoryId"] = this.category;
            data["description"] = this.description;
            axios({
                url: "/course/sub-category/add",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = "Added Sucessfully";
                        this.showsnackbar = true;
                        this.dialog = false;
                        this.name = null;
                        this.category = null;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        itemedit(item) {
            this.itemediting = item;
            this.HSN = this.itemediting.HSN;
            this.gstPercentage = this.itemediting.gstPercentage;
            this.gstLevel2Percentage = this.itemediting.gstLevel2Percentage;
            this.gstLevel2MinumumPrice = this.itemediting.gstLevel2MinumumPrice;
            this.a = this.a.concat(this.itemediting.size);

            this.editdialog = true;
        },
        edit() {
            var data = {};
            data["id"] = this.itemediting._id;
            data["name"] = this.itemediting.name;
            data["description"] = this.itemediting.description;
            axios({
                url: "/course/sub-category/edit",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
                params: {
                    id: this.$route.params.id,
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.editdialog = false;
                    if (response.data.status) {
                        this.msg = "Edited Sucessfully";
                        this.a = [];
                        this.showsnackbar = true;
                        this.dialog = false;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        info(scid) {
            // this.$router.push({
            //     name: "subcategoryView",
            //     params: { subid: scid },
            // });
            this.$router.push("/subcategoryView?id=" + scid);
        },
    },
};
</script>
  